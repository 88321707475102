<template>
  <div id="markdown">
    <code>
      <p>## WP Scan</p>
      <p>{{ wpscan }}</p>
      <p v-if="wpscanReport.length > 0"><pre>{{ wpscanReport }}</pre></p>

      <p>## Rapport</p>
      <ul>
        <li v-for="item in report" :key="item.plugin_name">
      <p>### {{ item.plugin_name }}</p>
      <p>Mise à jour disponible : {{ item.current_version }} → {{ item.target_version }}</p>
      <p>Analyse du [changelog]({{ item.changelog_link }})</p>
      <p><pre>{{ item.changes }}</pre></p>
      <p>Recommendation : {{ item.recommendation }}</p>
        </li>
      </ul>
    </code>

    <div class="actions">
      <div class="copy" role="button" ref="copy" @click="copy()">
        <img src="../assets/copy.png" alt="copy">
      </div>
      <span class="tooltip">Copied !</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Markdown',
  props: {
    wpscan: {
      type: String,
    },
    wpscanReport: {
      type: String,
    },
    report: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    copy() {
      const text = document.querySelector('#markdown code').innerText;

      navigator.clipboard.writeText(text).then(() => {
        const tooltip = document.querySelector('.tooltip');
        tooltip.classList.add('show');

        setTimeout(() => {
          tooltip.classList.remove('show');
        }, 1200);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#markdown {
  position: relative;
  width: calc(100% - 150px);
  overflow: visible;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  p,
  pre,
  code {
    margin: 0;
  }

  code {
    display: block;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 6px;
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    .copy {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      padding: 8px;
      border-radius: 50px;
      background-color: #fbc450;
      box-sizing: border-box;
      transition: background 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #fbd175;
      }

      img {
        width: 14px;
        height: 14px;
        object-fit: contain;
      }
    }

    .tooltip {
      position: absolute;
      top: 0;
      left: 40px;
      display: block;
      color: #fff;
      padding: 4px;
      border-radius: 4px;
      white-space: nowrap;
      background-color: rgba(#000, 0.8);
      transform: translateX(-10px);
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

      &.show {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

</style>
