<template>
  <div class="report">
    <h2 class="title">What's happened with WP Scan ?</h2>
    <ul class="choices-list">
      <li class="choices-list-item">
        <input type="radio" id="all_good" value="all_good" v-model="wpscanChoice">
        <label for="all_good">✅ All good</label>
      </li>
      <li class="choices-list-item">
        <input type="radio" id="warnings" value="warnings" v-model="wpscanChoice">
        <label for="warnings">🚧 Warning(s) to report</label>
      </li>
      <li class="choices-list-item">
        <input type="radio" id="errors" value="errors" v-model="wpscanChoice">
        <label for="errors">🚨 Error(s) to report</label>
      </li>
      <li class="choices-list-item">
        <input type="radio" id="skipped" value="skipped" v-model="wpscanChoice">
        <label for="skipped">⏭ WP Scan skipped</label>
      </li>
    </ul>

    <div class="wpscan-report" v-if="wpscanChoice === 'warnings' || wpscanChoice === 'errors'">
      <label for="wpscan_list">WP Scan report</label>
      <textarea id="wpscan_list" v-model="wpscanReport" placeholder="Add '-' in each new line">
      </textarea>
    </div>

    <h2 class="title">Prepare your WordPress report</h2>
    <div class="report-modal">
      <div class="report-modal__question">
        <label for="plugin_name">1. Plugin name</label>
        <input type="text" id="plugin_name" v-model="report.plugin_name">
      </div>

      <div class="report-modal__question">
        <label for="current_version">2. Actual version</label>
        <input type="text" id="current_version" v-model="report.current_version">
      </div>

      <div class="report-modal__question">
        <label for="target_version">3. Target version</label>
        <input type="text" id="target_version" v-model="report.target_version">
      </div>

      <div class="report-modal__question">
        <label for="changelog_link">4. Changelog link</label>
        <input type="url" id="changelog_link" v-model="report.changelog_link">
      </div>

      <div class="report-modal__question">
        <label for="changes">4. Changes</label>
        <textarea id="changes" v-model="report.changes" placeholder="Add '-' in each new line">
        </textarea>
      </div>

      <div class="report-modal__question">
        <span class="label">5. Recommendation</span>
        <ul class="choices-list">
          <li class="choices-list-item">
            <input type="radio" id="soft_update" value="soft_update" v-model="report.recommendation">
            <label for="soft_update">Update, without urgency</label>
          </li>
          <li class="choices-list-item">
            <input type="radio" id="hard_update" value="hard_update" v-model="report.recommendation">
            <label for="hard_update">Need update</label>
          </li>
          <li class="choices-list-item">
            <input type="radio" id="no_update" value="no_update" v-model="report.recommendation">
            <label for="no_update">Don't update</label>
          </li>
        </ul>
      </div>

      <div class="report-modal__actions">
        <div class="send" role="button" @click="addReport()">Add to report</div>
      </div>
    </div>

    <h2>Render:</h2>
    <Markdown :wpscan="wpscan" :wpscanReport="wpscanReport" :report="finalReport" />
  </div>
</template>

<script>
import Markdown from './Markdown.vue';

export default {
  name: 'Report',
  components: {
    Markdown,
  },
  data() {
    return {
      wpscanChoice: null,
      wpscanReport: '',
      report: {},
      finalReport: [],
    };
  },
  computed: {
    wpscan() {
      const texts = {
        all_good: '✅ Rien à signaler',
        warnings: '🚧 Warnings',
        errors: '❌ Erreurs',
        skipped: '⏭ Skipped',
      };

      return texts[this.wpscanChoice];
    },
  },
  methods: {
    addReport() {
      if (Object.values(this.report).length > 0) {
        const texts = {
          soft_update: 'Mettre à jour, sans urgence',
          hard_update: 'Mettre à jour',
          no_update: 'Ne pas mettre à jour',
        };

        if (this.report.recommendation && this.report.recommendation.length > 0) {
          this.report.recommendation = texts[this.report.recommendation];
        }

        this.finalReport.push(this.report);
        this.report = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  h3 {
    margin: 40px 0 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #fbc450;
  }

  .choices-list {
    &-item {
      margin-bottom: 6px;

      input[type="radio"] {
        margin-right: 10px;
      }
    }
  }

  .wpscan-report {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    label {
      margin-bottom: 6px;
    }

    textarea {
      width: 600px;
    }
  }

  .report-modal {
    display: flex;
    flex-direction: column;

    &__question {
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 15px;

      label,
      .label {
        margin-bottom: 6px;
      }

      input[type="text"],
      input[type="url"] {
        width: 250px;
      }

      input[type="radio"] {
        margin-right: 10px;
      }

      textarea {
        width: 600px;
      }
    }

    &__actions {
      display: flex;

      .send {
        display: inline-block;
        background-color: #fbc450;
        border-radius: 4px;
        padding: 6px 10px;
        color: #000;
        transition: background 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: #fbd175;
        }
      }
    }
  }
}
</style>
