<template>
  <div id="app">
    <div class="logo-container">
      <img alt="Logo" class="logo" src="./assets/logo.svg">
    </div>

    <Report/>
  </div>
</template>

<script>
import Report from './components/Report.vue';

export default {
  name: 'App',
  components: {
    Report,
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;

  .logo {
    width: 120px;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
